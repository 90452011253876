import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../components/Header';
import SalesOption from '../../components/SalesOption';
import Button from '../../components/Button';
//melhorar imports de styles
import { HomeWrapper, Content, SalesOptionWrapper, UserNameText, StationText, EstimatedTimeValue, HeaderContainer, EstimatedTimeContainer, UserStationContainer, EstimatedTimeText } from './HomeStyles';
import { CentralizedElement } from '../../styles/StyledComponents';
import { orderService } from '../../services/orderService';
import { ModalBody } from '../../styles/GlobalStyles';
import Input from '../../components/Input';
import { useNavigate } from 'react-router-dom';
import { authService } from '../../services/authService';
import { queueService } from '../../services/queueService';

const Home: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [washingTypes, setWashingTypes] = useState<{ id: number; quantity: number }[]>([]);
    const [quantities, setQuantities] = useState<{ [key: number]: number }>({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [vehiclePlate, setVehiclePlate] = useState('');
    const [userName, setUserName] = useState('');
    const [userStation, setUserStation] = useState('');
    const [isWednesday, setIsWednesday] = useState(false);
    const [isValidCarPlate, setIsValidCarPlate] = useState(false);
    const washingTypeIds: number[] = [];
    const navigate = useNavigate();
    const [userInfos] = useState(authService.getUserInfos());
    const [estimatedTime, setEstimatedTime] = useState(0);

    useEffect(() => {
        const token = userInfos?.token;
        const station = userInfos?.station;

        if (userInfos) {
            setUserName(userInfos.username ? userInfos.username : 'Nome de usuário não encontrado');
            setUserStation(userInfos.station ? userInfos.station : '');
        }

        const today = new Date().getDay();
        setIsWednesday(today === 3);

        const fetchEstimatedTime = () => {
            if (token && station) {
                queueService.getQueue(token, station)
                    .then((data) => {
                        setEstimatedTime(data.waitTime);
                    })
                    .catch((error) => {
                        console.error('Erro ao buscar fila: ', error);
                    });
            }
        };

        fetchEstimatedTime();

        const intervalId = setInterval(fetchEstimatedTime, 60000);

        return () => clearInterval(intervalId);
    }, [userInfos]);

    const verifyCarPlate = (plate: string) => {
        setVehiclePlate(plate);
        if (plate.length >= 7) {
            setIsValidCarPlate(true);
        } else {
            setIsValidCarPlate(false);
        }
    }

    const countWashingTypes = () => {
        washingTypes.forEach(({ id, quantity }) => {
            for (let i = 0; i < quantity; i++) {
                washingTypeIds.push(id);
            }
        });
    };

    const handleOpenModal = () => {
        countWashingTypes();

        if (washingTypeIds.length === 0) {
            toast.warning('Selecione pelo menos um item para confirmar a venda.');
            return;
        } else {
            setIsModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleConfirmOrder = async () => {
        setLoading(true);

        const token = userInfos.token;

        try {
            if (token) {
                countWashingTypes();
                await orderService.createOrder(washingTypeIds, vehiclePlate.toUpperCase(), token);
                toast.success('Venda confirmada com sucesso!');
                setWashingTypes([]);
                setQuantities({});
                setVehiclePlate('');
                handleCloseModal();
            } else {
                toast.error('Token de autenticação não encontrado.');
            }
        } catch (error: any) {
            if (error.response.status === 403) {
                toast.error("Sua sessão expirou. Faça login novamente.");
                setTimeout(() => {
                    authService.logout(navigate);
                }, 5000);
            } else {
                toast.error('Erro ao confirmar venda.');
                handleCloseModal();
            }
        } finally {
            setLoading(false);
        }
    };

    const updateWashingTypeQuantity = (id: number, quantity: number) => {
        setWashingTypes((prev) => {
            const existingType = prev.find((type) => type.id === id);
            if (existingType) {
                return prev.map((type) =>
                    type.id === id ? { ...type, quantity } : type
                );
            } else {
                return [...prev, { id, quantity }];
            }
        });

        setQuantities((prev) => ({
            ...prev,
            [id]: quantity,
        }));
    };

    // IDs de SalesOptions com base no userStation
    const salesOptionsForStationZ1OrZ7 = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const salesOptionsForOtherStations = [1, 2, 3, 5, 6, 8, 9];

    const getSalesOptionsToShow = (station: string) => {
        return station === 'Z1' || station === 'Z7'
            ? salesOptionsForStationZ1OrZ7
            : salesOptionsForOtherStations;
    };

    const salesOptionsToShow = getSalesOptionsToShow(userStation);

    return (
        <HomeWrapper>
            <Header />
            <HeaderContainer>
                <EstimatedTimeContainer>
                    {estimatedTime > 0 ? (
                        <EstimatedTimeText>Tempo estimado de fila: <EstimatedTimeValue>{estimatedTime} {estimatedTime > 1 ? "minutos" : "minuto"}</EstimatedTimeValue></EstimatedTimeText>
                    ) : (
                        <EstimatedTimeText> Sem fila</EstimatedTimeText>
                    )}
                </EstimatedTimeContainer>
                <UserStationContainer>
                    <UserNameText>{userName}</UserNameText>
                    <StationText>Posto {userStation}</StationText>
                </UserStationContainer>
            </HeaderContainer>
            <Content>
                <SalesOptionWrapper>
                    <CentralizedElement>
                        <h2>Carros</h2>
                    </CentralizedElement>
                    {salesOptionsToShow.includes(1) && (
                        <SalesOption id={1} name="Lavação 1" price="23,90" quantity={quantities[1] || 0} onQuantityChange={updateWashingTypeQuantity} />
                    )}
                    {salesOptionsToShow.includes(2) && (
                        <SalesOption id={2} name="Lavação 2" price="27,90" quantity={quantities[2] || 0} onQuantityChange={updateWashingTypeQuantity} />
                    )}
                    {salesOptionsToShow.includes(3) && (
                        <SalesOption id={3} name="Lavação 3" price="29,90" quantity={quantities[3] || 0} onQuantityChange={updateWashingTypeQuantity} />
                    )}
                    {salesOptionsToShow.includes(4) && (
                        <SalesOption id={4} name="Lavação 4" price="35,90" quantity={quantities[4] || 0} onQuantityChange={updateWashingTypeQuantity} />
                    )}
                </SalesOptionWrapper>
                <SalesOptionWrapper>
                    <CentralizedElement>
                        <h2>Promocionais</h2>
                    </CentralizedElement>
                    {isWednesday && (
                        <SalesOption id={8} name="Quarta das mulheres" price="27,90" quantity={quantities[8] || 0} onQuantityChange={updateWashingTypeQuantity} />
                    )}
                    <SalesOption id={9} name="Motorista de aplicativo" price="23,90" quantity={quantities[9] || 0} onQuantityChange={updateWashingTypeQuantity} />
                </SalesOptionWrapper>
                <SalesOptionWrapper>
                    <CentralizedElement>
                        <h2>Vans</h2>
                    </CentralizedElement>
                    {salesOptionsToShow.includes(5) && (
                        <SalesOption id={5} name="Lavação 1" price="33,90" quantity={quantities[5] || 0} onQuantityChange={updateWashingTypeQuantity} />
                    )}
                    {salesOptionsToShow.includes(6) && (
                        <SalesOption id={6} name="Lavação 2" price="37,90" quantity={quantities[6] || 0} onQuantityChange={updateWashingTypeQuantity} />
                    )}
                    {salesOptionsToShow.includes(7) && (
                        <SalesOption id={7} name="Lavação 3" price="42,90" quantity={quantities[7] || 0} onQuantityChange={updateWashingTypeQuantity} />
                    )}
                </SalesOptionWrapper>
            </Content>
            <CentralizedElement>
                <Button title="Confirmar venda" onClick={handleOpenModal} />
            </CentralizedElement>

            <Modal
                isOpen={isModalOpen}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
                contentLabel="Placa do Veículo"
                ariaHideApp={false}
                style={{
                    content: ModalBody
                }}
            >
                <Input
                    type="text"
                    label="Placa do veículo"
                    value={vehiclePlate}
                    onChange={(e) => verifyCarPlate(e.target.value)}
                    style={{ textTransform: "uppercase", textAlign: "center" }}
                    maxLenght={7}
                />
                <CentralizedElement>
                    <Button title="Finalizar" onClick={handleConfirmOrder} disabled={!isValidCarPlate} loading={loading} style={{ marginTop: "14px" }} />
                </CentralizedElement>
            </Modal>

            <ToastContainer />
        </HomeWrapper>
    );
};

export default Home;
