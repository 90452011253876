import React from 'react';
import { ButtonWrapper, Spinner } from './ButtonStyles';

interface ButtonProps {
    title: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    loading?: boolean;
    style?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = ({ title, onClick, disabled = false, loading = false, style }) => {
    return (
        <ButtonWrapper onClick={onClick} disabled={disabled || loading} style={style}>
            {loading ? <Spinner /> : title}
        </ButtonWrapper>
    );
};

export default Button;
