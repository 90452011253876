import styled from "styled-components";
import { Colors } from "../../styles/Colors";

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 100%;
`;

export const StyledInput = styled.input`
    padding: 10px 20px;
    width: 100%;
    border-radius: 25px;
    border: 2px solid ${Colors.primary[40]};
    background-color: ${Colors.primary[20]};
    color: ${Colors.commons["dark-gray"]};
    transition: border 0.3s;
    box-sizing: border-box;
    &::placeholder {
        color: ${Colors.commons["dark-gray"]};
    }
    &:focus {
        border-color: ${Colors.primary[40]};
        outline: none;
    }
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: 2px;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${Colors.commons.black};
  box-sizing: border-box;
`;