export const Colors = {
  "bg-light": "#f2f2f2",
  commons: {
    white: "#ffffff",
    black: "#1E1E1E",
    gray: "#D9D9D9",
    "dark-gray": "##555555",
  },
  primary: {
    100: "#3C3972",
    80: "#3C397280",
    60: "#3C397260",
    40: "#3C397240",
    20: "#3C397220",
  },
  secondary: {
    100: "#ED3237",
    80: "#ED323780",
    60: "#ED323760",
    40: "#ED323740",
    20: "#ED323720",
  },
  green: {
    100: "#028C22",
    80: "#028C2280",
    60: "#028C2260",
    40: "#028C2240",
    20: "#028C2220",
  },
  "light-blue": {
    100: "#D1ECFF",
    80: "#D1ECFF80",
    60: "#D1ECFF60",
    40: "#D1ECFF40",
    20: "#D1ECFF20",
  },
  yellow: {
    100: "#E6DD18",
    80: "#E6DD1880",
    60: "#E6DD1860",
    40: "#E6DD1840",
    20: "#E6DD1820",
  },
};
