import styled from "styled-components";
import { Colors } from "../../styles/Colors";

export const SalesOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.commons.white};
  border-radius: 10px;
  padding: 10px;
  padding-left: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 450px;
  min-width: 250px;
  margin-bottom: 10px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
  padding-right: 15px;
  color: ${Colors.primary[100]};
  font-weight: bold;
  font-size: 20px;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: ${Colors.secondary[100]};
`;

export const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.commons.white};
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-width: 50px;
  span {
    font-size: 18px;
    color: ${Colors.commons.black};
    margin: 5px 0;
  }
`;

export const QuantityButton = styled.button`
  background: none;
  border: none;
  color: ${Colors.primary[100]};
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
`;
