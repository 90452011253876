import axios from "./axiosInstance";

export const authService = {
  login: async (login: string, password: string) => {
    try {
      const response = await axios.post("/login", {
        login,
        password,
      });
      sessionStorage.setItem("authToken", response.data.token);
      sessionStorage.setItem(
        "userName",
        response.data.full_name !== undefined
          ? response.data.full_name
          : "Nome de usuário não encontrado"
      );
      sessionStorage.setItem(
        "userStation",
        response.data.station !== undefined ? response.data.station : ""
      );
      return response.data;
    } catch (error) {
      throw new Error("Credenciais inválidas");
    }
  },

  logout: (navigate: (path: string) => void) => {
    sessionStorage.removeItem("authToken");
    navigate("/login");
  },

  getUserInfos: () => {
    const obj = {
        username: sessionStorage.getItem("userName"),
        station: sessionStorage.getItem("userStation"),
        token: sessionStorage.getItem("authToken"),
    };
    return obj;
  },
};
