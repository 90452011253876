import { createGlobalStyle } from "styled-components";
import { Colors } from "./Colors";

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Lexend', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: ${Colors["bg-light"]};
  }
`;

export const ModalBody = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  padding: "24px",
};
