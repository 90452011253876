import styled from "styled-components";
import { Colors } from "../../styles/Colors";

export const HomeWrapper = styled.div`

`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
  margin-top: 0px;
`;

export const SalesOptionWrapper = styled.div`
  margin: 20px;
`;

export const UserNameText = styled.p`
	margin-bottom: 0px;
	font-size: 18px;
	font-weight: bold;
`;

export const StationText = styled.p`
	margin: 0px;
`;

export const EstimatedTimeText = styled.p`
	display: flex;
`;

export const EstimatedTimeValue = styled.p`
	font-weight: bold;
	margin: 0;
	margin-left: 5px;
	color: ${Colors.primary[100]};
`;

export const HeaderContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const UserStationContainer = styled.div`
	flex: 1;
	margin-right: 20px;
	justify-items: right;
`;

export const EstimatedTimeContainer = styled.div`
	flex: 1;
	margin-left: 20px;
	font-size: 20px;
	justify-items: left;
`;
