import styled from "styled-components";

export const CentralizedElement = styled.div`
  display: flex;
  justify-content: center;
`;

export const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
