import axios from "./axiosInstance";

export const orderService = {
  createOrder: async (
    washingTypeIds: number[],
    customer: string,
    token: string
  ) => {
    try {
      const response = await axios.post(
        "/orders/",
        { washingTypeIds, customer },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.error("Erro ao confirmar venda: ", error);
      throw error;
    }
  },
};
