import styled from "styled-components";
import { Colors } from "../../styles/Colors";

export const HeaderWrapper = styled.div`
  background-color: ${Colors.commons.white};
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const LogoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;

export const Logo = styled.img`
  height: 40px;
`;

export const LogoutButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: ${Colors.secondary["100"]};
`;
