import React from 'react';
import { HeaderWrapper, Logo, LogoutButton } from './HeaderStyles';
import { SignOut } from 'phosphor-react';
import { authService } from '../../services/authService';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        authService.logout(navigate);
    };

    return (
        <HeaderWrapper>
            <Logo src={require('../../assets/images/logo-super-ducha.png')} alt="Super Ducha" />
            <LogoutButton onClick={handleLogout}>
                <SignOut size={24} />
            </LogoutButton>
        </HeaderWrapper>
    );
};

export default Header;
