import React, { useEffect, useState } from 'react';
import PositionBadge from '../../components/PositionBadge';
import { Container, Header, BadgeList, Footer, FooterText, BoldText } from './DashboardStyles';
import { queueService } from '../../services/queueService';
import { authService } from '../../services/authService';

interface VehicleData {
    id: number;
    position: number;
    plate: string;
}

const Dashboard: React.FC = () => {
    const [vehicleData, setVehicleData] = useState<VehicleData[]>([]);
    const [estimatedTime, setEstimatedTime] = useState(0);
    const [userInfos] = useState(authService.getUserInfos());

    useEffect(() => {
        const token = userInfos?.token;
        const station = userInfos?.station;

        const fetchQueue = () => {
            if (token && station) {
                queueService.getQueue(token, station)
                    .then((data) => {
                        const plates: VehicleData[] = [];
                        data.queue.forEach((item: any, index: any) => {
                            plates.push({ id: index, position: index + 1, plate: item.plate });
                        });
                        setVehicleData(plates);
                        setEstimatedTime(data.waitTime);
                    })
                    .catch((error) => {
                        console.error('Erro ao buscar fila: ', error);
                    });
            }
        };

        fetchQueue();

        const intervalId = setInterval(fetchQueue, 30000);

        return () => clearInterval(intervalId);
    }, [userInfos]);

    return (
        <Container>
            <Header>PRÓXIMOS VEÍCULOS</Header>
            <BadgeList>
                {vehicleData.map((vehicle) => (
                    <PositionBadge key={vehicle.id} position={vehicle.position} plate={vehicle.plate} />
                ))}
            </BadgeList>
            <Footer>
                {estimatedTime > 0 ? (
                    <FooterText>
                        Tempo estimado de fila: <BoldText>{estimatedTime} {estimatedTime > 1 ? ' minutos' : 'minuto'}</BoldText>
                    </FooterText>
                ) : (
                    <FooterText>Sem fila</FooterText>
                )}
            </Footer>
        </Container>
    );
};

export default Dashboard;
