import React, { useState, useEffect } from 'react';
import { SalesOptionWrapper, InfoWrapper, PriceWrapper, QuantityWrapper, QuantityButton } from './SalesOptionStyles';
import { Plus, Minus } from 'phosphor-react';

interface SalesOptionProps {
    id: number;
    name: string;
    price: string;
    quantity: number; // Add this prop
    onQuantityChange: (id: number, quantity: number) => void;
}

const SalesOption: React.FC<SalesOptionProps> = ({ id, name, price, quantity, onQuantityChange }) => {
    const [currentQuantity, setCurrentQuantity] = useState(quantity);

    useEffect(() => {
        setCurrentQuantity(quantity); // Update state when prop changes
    }, [quantity]);

    const increaseQuantity = () => setCurrentQuantity(currentQuantity + 1);
    const decreaseQuantity = () => {
        if (currentQuantity > 0) {
            setCurrentQuantity(currentQuantity - 1);
        }
    };

    useEffect(() => {
        onQuantityChange(id, currentQuantity);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuantity]);

    return (
        <SalesOptionWrapper>
            <InfoWrapper>
                <span>{name}</span>
                <PriceWrapper>
                    <span>R$ </span>
                    <span>{price}</span>
                </PriceWrapper>
            </InfoWrapper>
            <QuantityWrapper>
                <QuantityButton onClick={increaseQuantity}>
                    <Plus />
                </QuantityButton>
                <span>{currentQuantity}</span>
                <QuantityButton onClick={decreaseQuantity}>
                    <Minus />
                </QuantityButton>
            </QuantityWrapper>
        </SalesOptionWrapper>
    );
};

export default SalesOption;
