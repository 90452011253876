import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { authService } from "../../services/authService";
import { LoginWrapper, Form, ErrorMessage, Logo } from "./LoginStyles";

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    try {
      const loginData = await authService.login(login, password);
      if (loginData.full_name === 'ADMIN') {
        navigate("/dashboard");
      } else {
        navigate("/home");
      }
    } catch (err) {
      setError("Não foi possível autenticar. Verifique suas credenciais.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginWrapper>
      <Logo
        src={require("../../assets/images/logo-super-ducha.png")}
        alt="Super Ducha"
      />
      <Form onSubmit={handleLogin}>
        <h3>Entre com suas credenciais</h3>
        <Input
          type="text"
          label="Usuário"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          required
        />
        <Input
          type="password"
          label="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Button
          title="Entrar"
          onClick={(e) => handleLogin(e)}
          style={{ marginTop: "10vh" }}
          loading={loading}
        />
      </Form>
    </LoginWrapper>
  );
};

export default Login;
