import styled from "styled-components";
import { Colors } from "../../styles/Colors";

export const Container = styled.div`
    display: flex;
    align-items: center;
    background-color: ${Colors.commons.black};
    border-radius: 8px;
    padding: 2vw;
    margin: 3vw;
    border: 2px solid ${Colors.yellow[100]};
    width: 60%;
`;

export const PositionContainer = styled.div`
    background-color: ${Colors.yellow[100]};
    padding: 2vw;
    border-radius: 8px;
    margin-right: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PositionText = styled.span`
    color: ${Colors.commons.black};
    font-size: 7vw;
    font-weight: bold;
`;

export const PlateContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const PlateText = styled.span`
    color: ${Colors.yellow[100]};
    font-size: 9vw;
    font-weight: bold;
`;