import React from 'react';
import { Container, PositionContainer, PlateContainer, PositionText, PlateText } from './PositionBadgeStyles';

interface PositionBadgeProps {
    position: number;
    plate: string;
}

const PositionBadge: React.FC<PositionBadgeProps> = ({ position, plate }) => {
    return (
        <Container>
            <PositionContainer>
                <PositionText>{position}</PositionText>
            </PositionContainer>
            <PlateContainer>
                <PlateText>{plate}</PlateText>
            </PlateContainer>
        </Container>
    );
};

export default PositionBadge;
