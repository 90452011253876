import React, { useState } from 'react';
import { Eye, EyeSlash } from 'phosphor-react';
import { InputWrapper, StyledInput, IconWrapper } from './InputStyles';

interface InputProps {
    type: string;
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    style?: React.CSSProperties;
    maxLenght?: number;
}

const Input: React.FC<InputProps> = ({ type, label, value, onChange, required = false, style, maxLenght }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <InputWrapper>
            <StyledInput
                type={showPassword ? 'text' : type}
                value={value}
                onChange={onChange}
                placeholder={label}
                required={required}
                style={style}
                maxLength={maxLenght}
            />
            {type === 'password' && (
                <IconWrapper onClick={handleTogglePassword}>
                    {showPassword ? <EyeSlash size={20} /> : <Eye size={20} />}
                </IconWrapper>
            )}
        </InputWrapper>
    );
};

export default Input;
