import styled from "styled-components";
import { Colors } from "../../styles/Colors";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-bottom: 5vw;
    min-height: 100vh;
    background-color: ${Colors.commons.black};
`;

export const Header = styled.h1`
    font-size: 7vw;
    font-weight: bold;
    margin: 4vw;
    color: ${Colors.yellow[100]};
`;

export const BadgeList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    margin-bottom: 12vw;
`;

export const Footer = styled.div`
    position: fixed;
    bottom: 0;
    background-color: ${Colors.yellow[100]};
    padding: 5vw;
    width: 100%;
    text-align: center;
`;

export const FooterText = styled.span`
    font-size: 5vw;
    color: ${Colors.commons.black};
`;

export const BoldText = styled.span`
    font-weight: bold;
    color: ${Colors.commons.black};
`;
