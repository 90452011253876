import axios from "./axiosInstance";

export const queueService = {
    getQueue: async (token: string, station: string) => {
        try {
            const response = await axios.get(
                `/stations/queue?station=${station}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        } catch (error: any) {
            console.error("Erro ao buscar fila: ", error);
            throw error;
        }
    },
};
